import { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { Text } from "./Text";
import { Link, useLocation } from "react-router-dom";

/* These tab entries have to match to react router routes.
   The routes are all lowercase, no spaces. E.g., "myunify" instead of "My Unify".
   The current tab is read from the current path. */
const tabs = ["My Unify", "Applications", "Workflows", "New Org"];

const toUrlLink = (string) => string.replace(/\s+/g, "").toLowerCase();

const Tab = styled(Text.Body2).attrs({ className: "Tab" })`
  z-index: 10;
  text-align: center;
  margin: 0;
  box-sizing: border-box;

  a {
    /* The a tag is the react-router-dom Link */
    padding: 8px 32px; /* put padding here so the whole tab "bubble" is clickable */
    display: inline-block;
    text-decoration: none;
    color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.color.additional.dark.value
        : theme.color.additional.light.value};
  }
  &:hover {
    cursor: pointer;
  }
`;

const TabPill = styled(Tab).attrs({ className: "TabPill" })`
  /* The TabPill needs to know the dimensions of the Tabs
   so it requires the useLayoutEffect hook to set the ready flag. */
  color: transparent;
  position: absolute;
  background: ${({ theme }) => theme.color.additional.light.value};
  border-radius: 50px;
  top: 2px;
  bottom: 2px;
  left: ${({ tabRef }) => {
    const pillX = tabRef?.getBoundingClientRect()?.x || 0;
    const parentX = tabRef?.parentElement?.getBoundingClientRect()?.x || 0;
    return pillX - parentX + 2 /* 2 accounts for the inset */;
  }}px;
  width: ${({ tabRef }) => {
    const width =
      tabRef?.getBoundingClientRect()?.width - 4; /* 4 accounts for the inset */
    return width;
  }}px;
  transition: all 0.2s;
  z-index: 3;
`;

const TabsContainer = styled.div.attrs({ className: "TabsContainer" })`
  background: ${({ theme }) => theme.color.additional.dark.value};
  border-radius: 52px;
  display: inline-flex;
  margin: auto;
  position: relative;
`;

export const StyledUnifyTabsController = styled(({ className }) => {
  const [ready, setReady] = useState(false);
  const renderedTabs = useRef({});
  const addToRef = (ref, key) => (renderedTabs.current[key] = ref);

  const location = useLocation();
  const path = location.pathname.replace("/", "");

  useLayoutEffect(() => {
    setReady(true);
  }, []);

  return (
    <div className={`UnifyTabsController ${className}`}>
      <TabsContainer>
        {tabs.map((item) => {
          return (
            <Tab
              ref={(ref) => addToRef(ref, toUrlLink(item))}
              key={`Tab ${item}`}
              isSelected={path === toUrlLink(item)}
            >
              <Link to={toUrlLink(item)}>{item}</Link>
            </Tab>
          );
        })}
        {ready && (
          <TabPill tabRef={renderedTabs.current[path]}>
            {tabs[tabs.findIndex((item) => path === toUrlLink(item))]}
          </TabPill>
        )}
      </TabsContainer>
    </div>
  );
})`
  display: flex;
  justify-content: center;
`;

export const UnifyTabsController = (props) => (
  <StyledUnifyTabsController {...props} />
);
