import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { getFavoriteApps } from "utils/getFavoriteApps";
import { getFavoriteWorkflows } from "utils/getFavoriteWorkflows";
import { Backplane } from "components/Backplane";
import { useUser } from "store/useUser";
import { useSidebar } from "store/useSidebar";
import { GlobalHeader } from "components/GlobalHeader";
import { Sheet } from "components/Sheet";
import {
  SideSheetContainer,
  SideSheetMainWrapper,
  SideSheetOverlay,
  PreventFocus,
} from "cdk-radial";
import styled from "styled-components";

const SideSheetContent = styled.div``;
const StyledMainContent = styled.div`
  width: 100%;
`;

export const Main = styled(({ className }) => {
  const currentUser = useUser((state) => state.currentUser);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("MAIN currentUser", currentUser, currentUser === 0);
    if (!currentUser) navigate("/login");
    if (currentUser === 0) navigate("/login");
    if (location.pathname === "/") navigate("/neworg");
  }, [currentUser, location.pathname, navigate]);

  const user = useUser((state) => state.users[state.currentUser]);
  const [bannerOpen, setBannerOpen] = useState(false);

  const favoriteApps = getFavoriteApps(user);
  const favoriteFlows = getFavoriteWorkflows(user);

  const {
    sidebarName,
    sidebarContent,
    setSidebar,
    closeSidebar,
    width,
    setWidth,
  } = useSidebar(
    (state) => ({
      sidebarName: state.name,
      sidebarContent: state.content,
      setSidebar: state.setSidebar,
      closeSidebar: state.closeSidebar,
      width: state.width,
      setWidth: state.setWidth,
    }),
    shallow
  );

  const isCollapsed = !!!sidebarName; // !!sidebarName === true if not null

  const sidebarHandler = ({ component, name, width = 450 }) => {
    const shouldClose =
      (!!sidebarName && name === sidebarName) ||
      component === null ||
      name === null;
    if (shouldClose) {
      closeSidebar();
    } else {
      setWidth(width);
      setSidebar(component, name);
    }
  };

  return (
    <div className={`Main ${className}`}>
      <GlobalHeader sidebarHandler={sidebarHandler} />
      <SideSheetMainWrapper className="mainContent" isModal>
        <SideSheetOverlay
          className="SideSheetOverlay"
          isCollapsed={isCollapsed}
          onClick={closeSidebar}
        />
        <SideSheetContainer
          isCollapsed={isCollapsed}
          isModal
          width={`${width}px`}
        >
          <SideSheetContent>{sidebarContent}</SideSheetContent>
        </SideSheetContainer>
        <StyledMainContent>
          <PreventFocus isEnabled={!isCollapsed}>
            <Backplane
              favoriteApps={favoriteApps}
              favoriteFlows={favoriteFlows}
              bannerOpen={bannerOpen}
              setBannerOpen={setBannerOpen}
            />
            <Sheet />
          </PreventFocus>
        </StyledMainContent>
      </SideSheetMainWrapper>
    </div>
  );
})`
  display: grid;
  grid-template-rows: 40px 1fr;
  .SideSheetOverlay {
    background: transparent;
  }
  .mainContent {
    overflow-y: auto;
    height: calc(100vh - 40px);
    background: ${({ theme }) => theme.color.additional.light.value};
  }
`;
