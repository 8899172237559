import styled from "styled-components";
import { IconButton, IconExpandMore } from "cdk-radial";
import { Text } from "./Text";
import { PropTypes } from "prop-types";
import { Carousel } from "./Carousel/Carousel";
import { useSettings } from "../store/useSettings";
import { Welcome } from "./Welcome";

const Discover = styled(Text.Heading6)`
  align-items: center;
  display: flex;
  gap: 8px;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0.8px;
`;
const BannerClosed = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 4px auto 0;
  max-width: 1280px;
  width: 100%;
`;
const BannerOpen = styled.div``;

export const StyledBanner = styled(
  ({ bannerOpen, className, setBannerOpen }) => {
    return (
      <div
        className={`Banner ${className}`}
        onClick={() => setBannerOpen(!bannerOpen)}
      >
        {!bannerOpen && (
          <BannerClosed>
            <Welcome>Welcome to CDK Unify</Welcome>
            <Discover>
              Discover CDK Solutions
              <IconButton icon={<IconExpandMore />} text="" />
            </Discover>
          </BannerClosed>
        )}
        {bannerOpen && (
          <BannerOpen>
            <Carousel />
          </BannerOpen>
        )}
      </div>
    );
  }
)`
  box-sizing: border-box;
  width: 100%;
  background: ${({ bannerOpen, theme }) => {
    const isDarkMode = useSettings((state) => state.isDarkMode);
    if (bannerOpen) {
      if (isDarkMode) {
        return theme.color.additional.alwaysBlack.value;
      } else {
        return theme.color.gray[100].value;
      }
    } else {
      return "transparent";
    }
  }};

  padding: 0 16px;
  margin: 0 auto;
`;

export const Banner = (props) => <StyledBanner {...props} />;

Banner.propTypes = {
  bannerOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  setBannerOpen: PropTypes.func.isRequired,
};
