import styled from "styled-components";
import { Thumbnail } from "./Thumbnail";

export const Thumbnails = styled(
  ({ className, paneArray, container, onThumbnailClick }) => {
    const handleClick = (event, index) => {
      event.stopPropagation();
      onThumbnailClick(index);
    };

    return (
      <div className={className}>
        {paneArray.map((item, index) => (
          <Thumbnail
            key={`Thumbnail${index}`}
            className={index === container.slot1 ? "active" : ""}
            onClick={(event) => handleClick(event, index)}
          ></Thumbnail>
        ))}
      </div>
    );
  }
)`
  display: flex;
  gap: 8px;
  justify-content: center;
  position: absolute;
  top: 105px;
  margin: auto;
  left: 0;
  right: 0;
`;
