import styled from "styled-components";
import { Text } from "../Text";
export const Description = styled(Text.Body2)`
  margin: 0 16px 0 0;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  color: ${({ theme }) => theme.color.gray[900].value};

  font-weight: 500;
  line-height: 20px;
`;
