import styled from "styled-components";
import { useWindowWidth } from "../../utils/useBrowserWidth";

export const Container = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  &.move {
    left: ${() => {
      const windowWidth = useWindowWidth();
      return windowWidth > 1280 ? "-1280px" : `-${windowWidth - 22}px`;
    }};
    transition: left ${({ paneSlidingSpeed }) => paneSlidingSpeed} ease-in-out
      0s;
  }
`;
